// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import './app/app.theme.scss';

@import '~css-toggle-switch/dist/toggle-switch.css';

@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

[hidden] {
  display: none !important;
}

@import '~@ctrl/ngx-emoji-mart/picker';

.emoji-panel {
  padding: 0 !important;
}

#alabia-view-content {
  .header>* {
    display       : inline;
    vertical-align: middle;
    line-height   : 32px;
    margin-right  : 24px;
  }


  .alabia-view-container {
    margin: 16px;
  }
}

.alabia-wide-table {
  width: 100%;
}

.alabia-spinner-wrapper {
  display   : block;
  width     : 100%;
  height    : 100px;
  margin    : 16px 0;
  text-align: center;
}

.alabia-view-spinner {
  margin: 100px auto;
  width : 100px;
  height: 100px;
}

.channel-edit-wrapper,
.user-dialog-container {
  .mat-dialog-content {
    padding       : 24px;
    padding-bottom: 0;
    margin        : 0;
  }

  .mat-dialog-container {
    padding : 0;
    overflow: visible;
  }

  .mat-dialog-actions {
    padding: 16px;
    margin : 0;
  }
}

.qrcode-wrapper {
  width: 100%;

  canvas {
    width : 100% !important;
    height: auto !important;
  }
}

.alabia-fab {
  position: fixed !important;
  bottom  : 40px;
  right   : 40px;
}

.alabia-generic-wrapper {
  width  : 100%;
  padding: 16px;

  >* {
    width: 100%;
  }

  .alabia-fab {
    position: absolute;
    bottom  : 32px;
    right   : 32px;
  }
}